




















































































































































































































































































































































































































































import { Prop, Vue } from "vue-property-decorator";
import { Component, Mixins } from "vue-mixin-decorator";
import {
  CommentData,
  StampData,
  PostCommentData,
  PostCommentStampData,
  PostStampData,
  ActionTimelineData,
  UsedConsultantUserData,
} from "@/types";
import StampButton from "@/components/action_report/StampButton.vue";
import CommentUpdateModal from "@/components/action_report/CommentUpdateModal.vue";
import StampModal from "@/components/action_report/StampModal.vue";
import AutoUrlLink from "./AutoUrlLink.vue";
import StampService from "@/services/stamp_service";
import { default as ActionAnswerService } from '@/services/action_answer_service'
import ActionCommentService from "@/services/action_comment_service";
import { Code, Messages } from "@/const";
import ActionReportServiceHelper from "@/mixins/action_report_service_helper";

import { OverlayLoader } from '@/components/parts'
import Filters from '@/utils/filters'
import GoodTableHeader from "@/mixins/good_table_mixin";

interface MixinInterface
  extends ActionReportServiceHelper,
    GoodTableHeader<ActionTimelineData> {}

@Component({
  components: {
    CommentUpdateModal,
    StampButton,
    StampModal,
    OverlayLoader,
    AutoUrlLink,
  },
  filters: Filters,
})
export default class CommentListModal extends Mixins<MixinInterface>(
  ActionReportServiceHelper
) {
  /** コメント投稿対象者のユーザーID */
  @Prop()
  comments: Array<CommentData>;

  @Prop()
  memo: { memo: string; date: string };

  @Prop()
  answerTime: string;

  /** 閲覧ユーザーのコメントが存在するかの判定 */
  @Prop()
  inMyComment: boolean;

  /** コメント承認画面か否か */
  @Prop({default: false})
  isCommentConfirm: boolean;

  /** ナイショ話閲覧可能ユーザー名 */
  @Prop()
  hasHiddenComment: boolean;

  /** 選択回答index */
  @Prop()
  rowIdx: number;

  /** 回答スタンプ */
  @Prop()
  answerStamps: StampData[];

  /** マイか否か */
  @Prop({default: true})
  isMyAction: boolean;

  /** ログインユーザー名 */
  loginUserRole = this.$store.getters.userInfo.userRole;

  userId = "";

  answerDate = "";

  dailyAnswerId = "";

  /** スタンプ表示情報 */
  stamps: any = [];

  /** モーダルクリック時のcommentId保存 */
  actionCommentId: number | string = "";

  scrollHeights: number[] = [];

  inputComment = "";

  replyInputComment = "";

  /** テキストエリアのデフォルトの高さ */
  defaultTextareaHeight = 94;

  textAreaHeight: number = this.defaultTextareaHeight;

  /** 返信機能 */
  replyUser = "";
  replyUserId = 0;
  replyProfileImage = "";

  /** 編集機能 */
  currentEditCommentId = 0;
  editComment = "";
  editDate = "";
  editReplyUserId = 0;

  processing = false;

  usedConsultantStampUsers: Array<UsedConsultantUserData> = [];
  usedConsultantCommentUsers: Array<UsedConsultantUserData> = [];

  isConsultantStampDisable = false;
  isConsultantCommentDisable = false;

  isGenerating = false;

  /** スタンプ表示更新 */
  refreshStampDisp(dailyAnswerId: string, userId: string, answerDate: string) {
    Vue.$log.debug("refreshStampDisp");
    this.dailyAnswerId = dailyAnswerId;
    this.userId = userId;
    this.answerDate = answerDate;

    // 褒めるAIコンサルタント取得済みか確認
    this.usedConsultantStampUsers = this.$store.getters.usedConsultantStampUsers || [];
    if(this.usedConsultantStampUsers.some(e => e.userId == userId && e.date == answerDate)) {
      this.isConsultantStampDisable = true;
    } else {
      this.isConsultantStampDisable = false;
    }
    this.usedConsultantCommentUsers = this.$store.getters.usedConsultantCommentUsers || [];
    if(this.usedConsultantCommentUsers.some(e => e.userId == userId && e.date == answerDate)) {
      this.isConsultantCommentDisable = true;
    } else {
      this.isConsultantCommentDisable = false;
    }
    // コメントスタンプを更新
    new StampService()
      .getCommentStamp(this.dailyAnswerId)
      .then((response) => {
        Vue.$log.debug(response.data);
        const data = response.data;
        if (data && data.result) {
          this.stamps = data.result;
        }
      });
  }

  /**
   * スタンプ選択モーダル表示
   */
  clickShowStampModal(actionCommentId: number | string) {
    Vue.$log.debug("clickShowStampModal");
    this.actionCommentId = actionCommentId;
    Vue.$log.debug(this.actionCommentId);
    Vue.$log.debug(actionCommentId);
    this.$modal.show("comment-stamp-modal");
  }

  /** 閉じるイベント */
  clickCloseButton() {
    this.$emit("close");
  }

  closeStampModal() {
    Vue.$log.debug("closeStampModal");
  }

  successStampModal() {
    Vue.$log.debug("successStampModal");
  }

  /**
   * モーダルのスタンプクリック
   */
  clickModalStamp(stamp: StampData) {
    Vue.$log.debug(stamp.stampId);
    // stamp登録
    const stampData = {
      dailyAnswerId: this.dailyAnswerId,
      answerDate: this.answerDate,
      commentId: this.actionCommentId,
      stampId: stamp.stampId,
      userId: this.userId,
    };
    Vue.$log.debug("stampData");
    Vue.$log.debug(stampData);
    if(this.actionCommentId) {
      // commentIdがある場合はコメントスタンプ登録
      new StampService().postCommentStamp(stampData).then((response) => {
        if (this.commonApiErrorHandler(response)) {
          Vue.$log.debug(response.data);
          this.refreshStampDisp(this.dailyAnswerId, this.userId, this.answerDate);
        }
      });
    } else {
      // commentIdがない場合は回答スタンプ登録
      new StampService().postStamp(stampData).then((response) => {
        if (this.commonApiErrorHandler(response)) {
          Vue.$log.debug(response.data);
          this.$emit("stampRefresh", this.rowIdx, stampData);
        }
      });
    }
    this.$modal.hide("comment-stamp-modal");
  }

  /** 回答表示スタンプクリック */
  clickStampImage(stamp: StampData, answerDate: string, rowIdx: number) {
    if(!this.userId) {
      return
    }
    Vue.$log.debug(stamp);
    Vue.$log.debug(answerDate);
    // API用パラメータ
    const postData = {
      dailyAnswerId: this.dailyAnswerId,
      answerDate: answerDate,
      stampId: stamp.stampId,
      userId: this.userId,
    } as PostStampData;

    // 自スタンプがある場合は削除
    if(stamp.myStamp) {
      new StampService().deleteStamp(postData).then((response) => { if(this.commonApiErrorHandler(response)) {
        Vue.$log.debug('deleteStamp');
        this.$emit("stampRefresh", rowIdx, postData);
      } });
    }
    // 自スタンプがない場合は追加
    else {
      new StampService().postStamp(postData).then((response) => { if(this.commonApiErrorHandler(response)) {
        this.$emit("stampRefresh", rowIdx, postData);
      } });
    }
  }

  /**
   * コメント表示スタンプクリック
   */
  clickCommentStampImage(stamp: StampData, answerDate: string, rowIdx: number) {
    Vue.$log.debug("click comment stamp image");
    Vue.$log.debug(rowIdx);
    // API用パラメータ
    const postData = {
      dailyAnswerId: this.dailyAnswerId,
      answerDate: answerDate,
      stampId: stamp.stampId,
      commentId: rowIdx,
      userId: this.userId,
    } as PostCommentStampData;

    // 自スタンプがある場合は削除
    if (stamp.myStamp) {
      new StampService().deleteCommentStamp(postData).then((response) => {
        if (this.commonApiErrorHandler(response)) {
          Vue.$log.debug("deleteStamp");
          this.refreshStampDisp(this.dailyAnswerId, this.userId, this.answerDate);
        }
      });
    }
    // 自スタンプがない場合は追加
    else {
      new StampService().postCommentStamp(postData).then((response) => {
        if (this.commonApiErrorHandler(response)) {
          this.refreshStampDisp(this.dailyAnswerId, this.userId, this.answerDate);
        }
      });
    }
  }

  /** 
   * 褒めるAIコンサルタントスタンプボタンクリック 
   */
  clickConsultantStampButton() {
    const postData = {
      dailyAnswerId: this.dailyAnswerId,
      answerDate: this.answerDate,
      userId: this.userId,
    } as PostStampData;
    Vue.$log.debug(postData);

    new StampService().postConsultantStamp(postData).then((response) => {
      if (this.commonApiErrorHandler(response)) {
        this.$emit("stampRefresh", this.rowIdx, postData);
        // スタンプ取得済みに登録
        this.usedConsultantStampUsers.push({
          userId: this.userId,
          date: this.answerDate,
        });
        this.$store.commit("setUsedConsultantStampUsers", this.usedConsultantStampUsers);
        this.isConsultantStampDisable = true;
      }
    });
  }

  /**
   * 褒めるAIコンサルタント文案取得ボタンクリック
   */
  clickGetDraftButton() {
    if(this.processing) {
      return
    }
    this.isConsultantCommentDisable = true;
    this.processing = true;
    this.isGenerating = true;
    const textarea = document.getElementById("commentTextarea") as HTMLTextAreaElement;
    textarea.focus();
    // 入力テキストがある場合はリセット
    if(this.inputComment) {
      this.inputComment = "";
    }
    new ActionCommentService()
    .getConsultantPhrase(this.dailyAnswerId, this.userId)
    .then((response) => {
      if (this.commonApiErrorHandler(response)) {
        const data = response.data;
        Vue.$log.debug(data);
        if (data.code == Code.SUCCESS) {
          let phraseText = data.result.text;
          if (phraseText.length == 0) {
            this.processing = false;
            this.isGenerating = false;
            return;
          }
          // 回答をコメント取得済みに登録する
          this.usedConsultantCommentUsers.push({
            userId: this.userId,
            date: this.answerDate,
          });
          this.$store.commit("setUsedConsultantCommentUsers", this.usedConsultantCommentUsers);
          
          // 取得したフレーズを1文字ずつ表示
          for(let i = 0; i < phraseText.length; i++) {
            setTimeout(() => {
              // 別のテキストエリアを開いた場合は処理を中断
              if(i != 0 && this.inputComment.length == 0) {
                phraseText = "";
                this.processing = false;
                this.isGenerating = false;
                return;
              }
              this.inputComment += phraseText.slice(i, i + 1);
              if(i == phraseText.length - 1) {
                this.processing = false;
                this.isGenerating = false;
              }
            }, 60 * i);
          }
        } else if(data.code == Code.PHRASE_GENERATION_LIMIT) {
          this.$swal(Messages.PHRASE_GENERATION_LIMIT, '', 'warning');
          this.processing = false;
          this.isGenerating = false;
        } else {
          this.processing = false;
          this.isGenerating = false;
          this.isConsultantCommentDisable = false;
        }
      } else {
        this.processing = false;
        this.isGenerating = false;
        this.isConsultantCommentDisable = false;
      }
    });
  }

  /** 返信ユーザーを取得 */
  clickReply(
    replyUser: string,
    replyUserId: number,
    replyProfileImage: string
  ) {
    if(this.replyUserId !== replyUserId) {
      this.clearReplyInputComment();
    }
    this.replyUser = replyUser;
    this.replyUserId = replyUserId;
    this.replyProfileImage = replyProfileImage;
  }

  /** 返信クリア */
  clearReplyUser() {
    this.replyUser = "";
    this.replyProfileImage = "";
    if(this.replyInputComment.length !== 0) {
      return
    } else {
      this.replyUserId = 0;
    }
  }

  /** コメント編集 */
  clickEdit(comment: string, date: string, commentId: number, replyUserId = 0) {
    this.editReplyUserId = 0;
    this.editComment = comment;
    this.editDate = date;
    this.currentEditCommentId = commentId;
    if(replyUserId) {
      this.editReplyUserId = replyUserId;
    }

    this.$modal.show("comment-update-modal");
  }

  /** stampデータのclass出し分け */
  stampClass(stamp: StampData) {
    if (stamp.myStamp) {
      return "btn  actionreport--stamp actionreport--stamp-active";
    }
    return "btn  actionreport--stamp";
  }

  /** 入力時、テキストエリアの高さを動的に変更 */
  inputTextarea(e: Event) {
    this.textAreaHeight = (e.target as HTMLInputElement).scrollHeight;
  }

  /** コメント送信 */
  clickCommentButton() {
    if (!this.inputComment || this.processing) return;
    this.processing = true;
    const postData = {
      // answerDate: this.answerDate,
      // userId: this.userId,
      dailyAnswerId: this.dailyAnswerId,
      text: this.inputComment,
    } as PostCommentData;
    // ナイショ話回答のコメントの場合は、ポップアップを表示
    if (this.hasHiddenComment && this.loginUserRole != -1 && this.loginUserRole != 3) {
      this.$swal({
        title: "コメントは、ナイショ話が見えない人も確認できます。コメントをして問題ありませんか。",
        text: "※この回答のナイショ話を閲覧できる人の確認は、回答ごとの「ナイショ話」横にある「i」アイコンから確認できます。",
        confirmButtonText: "コメントする",
        cancelButtonText: "戻る",
        confirmButtonColor: "#5EB0DE",
        cancelButtonColor: "#FC7067",
        showCancelButton: true,
      }).then((isConfirm) => {
        if (isConfirm.isConfirmed) {
          this.postCommentData(postData);
        } else {
          this.processing = false;
        }
      });
    } else {
      this.postCommentData(postData);
    }
  }

  /** コメント返信 */
  clickReplyButton() {
    if (!this.replyInputComment || this.processing) return;
    this.processing = true;
    const postData = {
      dailyAnswerId: this.dailyAnswerId,
      text: this.replyInputComment,
      replyUserId: this.replyUserId,
    } as PostCommentData;
    this.postCommentData(postData);
  }

  // コメント投稿API呼び出し処理
  postCommentData(postData: PostCommentData) {
    this.showLoading();
    new ActionCommentService()
        .postComment(postData)
        .then((response) => {
          if (this.commonApiErrorHandler(response)) {
            const data = response.data;
            // SUCCESSの場合は成功通知
            if (data.code == Code.SUCCESS) {
              Vue.$log.debug(data.result.action);
              // if (this.$v.fields.text) {
              //   this.$v.fields.text.$reset();
              // }
              if(postData.replyUserId) {
                this.$emit("success", {
                  date: this.memo.date,
                  userId: this.userId,
                  replyUserId: postData.replyUserId,
                });
                this.clearReplyInputComment();
              } else {
                this.$emit("success", {
                  date: this.memo.date,
                  userId: this.userId,
                });
                this.clearInputComment();
              }
            }
            // その他のケースはエラー通知
            else {
              this.$swal(Messages.NETWORK_ERROR, "", "error");
            }
          }
        })
        .finally(() => {
          this.clearReplyUser();
          this.textAreaHeight = this.defaultTextareaHeight;
          this.processing = false;
          this.hideLoading();
        });
  }

  /** コメント削除 */
  clickDelete(commentId: number, replyUserId = 0) {
    const postData: {
      commentId: number;
      dailyAnswerId: string;
    } = {
      commentId: commentId,
      dailyAnswerId: this.dailyAnswerId,
    };

    this.$swal({
      title: "コメントを削除します",
      text: "削除された回答は復元することができません。よろしいですか？",
      confirmButtonColor: "#5EB0DE",
      cancelButtonColor: "#FC7067",
      showCancelButton: true,
    }).then((isConfirm) => {
      if (isConfirm.isConfirmed) {
        new ActionCommentService()
        .deleteComment(postData)
        .then((response) => {
          if(this.commonApiErrorHandler(response)) {
            const data = response.data;
            Vue.$log.debug(response.data);
            // SUCCESSの場合は成功通知
            if(data.code == Code.SUCCESS) {
                this.$emit("deleteSuccess", {
                  date: this.memo.date,
                  userId: this.userId,
                  replyUserId: replyUserId,
                });
                this.clearReplyInputComment();
                this.clearInputComment();
            }
            // その他のケースはエラー通知
            else {
              this.$swal(Messages.NETWORK_ERROR, "", "error");
            }
          }
        }).finally(() => {
          this.hideLoading();
        });
      }
    });
  }

// コメント承認
  clickCommentConfirm(commentId: number, text: string) {
    new ActionAnswerService()
    .PostActionPendingComment(commentId, text)
    .then((response) => {
      if(this.commonApiErrorHandler(response)) {
        const data = response.data;
        Vue.$log.debug(response.data);
        // SUCCESSの場合は成功通知
        if(data.code == Code.SUCCESS) {
          this.$emit("commentConfirm");
        }
        // その他のケースはエラー通知
        else {
          this.$swal(Messages.NETWORK_ERROR, "", "error");
        }
      }
    }).finally();
  }

  modalClosed() {
    this.scrollHeights = [];
  }

  commentUpdateSuccess(text: string, commentId: number, replyUserId: number) {
    this.$emit("commentUpdate", text, commentId, replyUserId);
    this.$modal.hide('comment-update-modal');
  }

  clearInputComment() {
    this.inputComment = "";
  }

  clearReplyInputComment() {
    this.replyInputComment = "";
  }
}
